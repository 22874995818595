// Burger code loosely based on: https://web.dev/articles/website-navigation
// https://css-tricks.com/in-praise-of-the-unambiguous-click-menu/
// https://gsap.com/docs/v3/GSAP/gsap.matchMedia()/
import gsap from 'gsap';
const button = document.getElementById('burger');
const navAnimEl = document.getElementById('nav-anim');
const header = document.getElementById('header');
let matchMedia = gsap.matchMedia();

matchMedia.add({
  isMobile: '(max-width: 959px)',
  isDesktop: '(min-width: 960px)'
}, function(context) {
  const { isMobile, isDesktop } = context.conditions;

  context.add('handleEscape', function(e) {
    if (e.code === 'Escape' && isMobile) {
      hide();
    }
  });

  context.add('handleClick', function(e) {
    const clickFromInside = e.target.closest('#main-nav');
    if (!clickFromInside && isMobile) {
      hide();
    }
  });

  if (isDesktop) {
    gsap.set(navAnimEl, {clearProps: 'all'});  
  } else {
    // Check if previously open/closed and restore state
    const isOpen = button.getAttribute('aria-expanded') === 'true';
    isOpen ? show(false) : hide(false);
  }

  // Hide nav when user presses Escape key or clicks outside nav area
  document.addEventListener('keyup', context.handleEscape);
  document.addEventListener('click', context.handleClick);

  return function() {
    // make sure to clean up event listeners in the cleanup function!
    document.removeEventListener('keyup', context.handleEscape);
    document.removeEventListener('click', context.handleClick);
  }
});

function hide(animate = true) {
  button.setAttribute('aria-expanded', false);
  const tl = gsap.timeline();
  tl.to(navAnimEl, {
    height: 0,
    duration: animate ? 0.1 : 0,
    ease: 'none',
  });
  tl.set(navAnimEl, {visibility: 'hidden'});
}

function show(animate = true) {
  button.setAttribute('aria-expanded', true);
  const tl = gsap.timeline();
  tl.set(navAnimEl, {
    visibility: 'visible', 
  });
  tl.fromTo(navAnimEl, {
    height: 0,
  }, {
    height: 'auto', 
    duration: animate ? 0.2 : 0,
  });
}

export default function() {
  // Toggle aria-expanded attribute
  button.addEventListener('click', function() {
    const isOpen = button.getAttribute('aria-expanded') === 'true';
    isOpen ? hide() : show();
  });
}