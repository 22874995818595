import projectsMap from './projects-map';
import projectsView from './projects-view';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// There should only be one filters form per page
const form = document.getElementById('filters-form');
const filters = document.querySelectorAll('.js-filter');
const ajaxContainer = document.getElementById('ajax-cards');

function ajax(options) {
  const { url, paginate = false, updateHistory = true } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add('loading-ajax-cards');

  axios.get(url).then((res) => {
    if (history && updateHistory) {
      history.pushState('', '', url);
    }
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const newContainer = resHTML.getElementById('ajax-cards');
    const newContent = newContainer.innerHTML;
    // Prevent height 'jump' when swapping content
    const height = ajaxContainer.offsetHeight;
    ajaxContainer.style.height = `${height}px`;
    ajaxContainer.innerHTML = newContent;
    ajaxContainer.style.height = `auto`;

    gsap.from('.js-ajax-card', {
      opacity: 0,
      y: 10,
      duration: 0.4,
      stagger: 0.1,
    });

    if (paginate) {
      gsap.to(window, {
        scrollTo: {
          y: form,
          offsetY: () => {
            const header = document.getElementById('header');
            const headerHeight = header.offsetHeight;
            return headerHeight;
          }
        },
        duration: 0.2,
      })
    }
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove('loading-ajax-cards');
  });
}

function handle(e) {
  if (e.type === 'submit') {
    e.preventDefault();
  }

  // Get map querystring if present
  const params = new URLSearchParams(window.location.search);
  const isMapView = params.get('map');
  const checked = Array.from(form.querySelectorAll('.js-filter:checked'));
  const href = window.location.href;
  // Always remove page number when updating filters
  let newUrl = href.includes('/page') ? href.split('/page')[0] : href.split('?')[0];
  // Use URL constructor to make it easier to add querystring params:
  // https://developer.mozilla.org/en-US/docs/Web/API/URL_API
  const urlObject = new URL(newUrl);
  if (checked.length) {
    const categories = Array.from(form.querySelectorAll('[name="categories[]"]:checked'), function(el) { return el.value; }).join('|');
    if (categories.length) {
      urlObject.searchParams.set('categories', categories);
    }
  }
  // Add map querstring back to url if need be
  if (isMapView) {
    urlObject.searchParams.set('map', 'true');
  }
  newUrl = urlObject.toString();
  ajax({url: newUrl});
  projectsMap.updateMarkers(newUrl);
}

function resetFilters() {
  const filters = Array.from(form.querySelectorAll('.js-filter'));
  filters.forEach(filter => filter.checked = false);
  // Get map querystring if present
  const params = new URLSearchParams(window.location.search);
  const isMapView = params.get('map');
  const href = window.location.href;
  // Always remove page number when updating filters
  let newUrl = href.includes('/page') ? href.split('/page')[0] : href.split('?')[0];
  // Use URL constructor to make it easier to add querystring params:
  // https://developer.mozilla.org/en-US/docs/Web/API/URL_API
  const urlObject = new URL(newUrl);
  // Add map querstring back to url if need be
  if (isMapView) {
    urlObject.searchParams.set('map', 'true');
  }
  newUrl = urlObject.toString();
  ajax({url: newUrl});
  projectsMap.updateMarkers(newUrl);
}

function paginate(link) {
  const url = decodeURIComponent(link.href);
  ajax({url: url, paginate: true});
}

export default function() {
  if (!form) return;
  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    const clicked = e.target;
    const paginateLink = clicked.closest('.js-filter-paginate-link');
    const resetButton = clicked.closest('#reset-filters');
    if (paginateLink) {
      e.preventDefault();
      paginate(paginateLink);
    } else if (resetButton) {
      resetFilters();
    }
  });

  window.addEventListener('popstate', (e) => {
    // Check page contains form
    const form = document.getElementById('filters-form');
    if (!form) return;
    const searchParams = new URLSearchParams(location.search);
    // Uncheck all filters
    filters.forEach(filter => filter.checked = false);
    // Then re-check the matching ones from the search params
    for (const p of searchParams) {
      const filtersFromParams = p[1].split('|');
      filtersFromParams.forEach(filter => {
        const checkbox = form.querySelector(`.js-filter[value="${filter}"]`);
        if (checkbox) {
          checkbox.checked = true;
        }
      });
    }
    projectsView.checkView({updateHistory: false});
    ajax({url: location.href, updateHistory: false});
    projectsMap.updateMarkers(location.href);
  });

  filters.forEach(filter => filter.addEventListener('change', handle));
}