// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

import tabbing from './modules/tabbing';
import skip from './modules/skip';
import nav from './modules/nav';
import filters from './modules/filters';
import videos from './modules/videos';
import animtext from './modules/animtext';
import accordion from './modules/accordion';
import projectsView from './modules/projects-view';
import projectsMap from './modules/projects-map';

tabbing();
skip();
nav();
filters();
videos();
accordion();
projectsView.init();
projectsMap.init();

const rootEL = document.documentElement;
rootEL.classList.remove('no-js');

window.addEventListener('load', function(e) {
  document.body.classList.remove('page-is-loading');
  animtext();
});

