let projectsEl;
let activeMapClass = 'projects-map-is-active';

function checkView(options) {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('map') === 'true') {
    showMap(options);
  } else {
    showList(options);
  }
}

function showList(options = {updateHistory: true}) {
  if (projectsEl) {
    projectsEl.classList.remove(activeMapClass);
    // Update URL to reflect map view
    const urlObject = new URL(window.location);
    urlObject.searchParams.delete('map', 'true');
    if (options.updateHistory) {
      history.pushState(null, '', urlObject.toString());  
    }
  };
}

function showMap(options = {updateHistory: true}) {;
  if (projectsEl) {
    projectsEl.classList.add(activeMapClass);
    // Update URL to reflect map view
    const urlObject = new URL(window.location);
    urlObject.searchParams.set('map', 'true');
    if (options.updateHistory) {
      history.pushState(null, '', urlObject.toString());  
    }
  };
}

const projectsView = {
  init() {
    document.addEventListener("change", function (e) {
      const clicked = e.target;
      const projectsMapBtn = clicked.closest("#projects-map-btn");
      const projectsListBtn = clicked.closest("#projects-list-btn");
      projectsEl = document.getElementById('projects');
      if (projectsListBtn) {
        showList();
      } else if (projectsMapBtn) {
        showMap();
      }
    });
  },
  checkView
};

export default projectsView;